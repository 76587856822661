<template>
  <v-app class="grey lighten-5">
    <section class="py-5 h-100 d-flex justify-content-center main-div-login">
      <v-card
        elevation="0"
        rounded="lg"
        :class="webbreakdown() ? 'w-100' : 'w-75'"
        class="m-auto login-subdiv"
      >
        <v-card-text class="p-4">
          <v-row class="w-100 m-0">
            <v-col cols="12" sm="12" lg="6" class="image-div">
              <h2 class="text-primary-color">{{ $t("appName") }}</h2>
              <br />
              <img
                alt="QRate"
                class="logo_img_lg w-100 h-75"
                src="../../assets/mobile_payments.png"
                style="max-height: 75% !important"
              />
            </v-col>

            <v-col cols="12" sm="12" lg="6" class="pb-2 login-main-div">
              <span class="d-flex justify-end">
                <v-btn
                  text
                  class="text-capitalize primary--text"
                  @click="contact_us = true"
                  ><v-icon class="pe-1">mdi-account-tie</v-icon>
                  {{ $t("customer_support") }}
                </v-btn>
              </span>
              <v-form v-model="valid" ref="form">
                <div class="w-100 m-auto p-2">
                  <h2
                    class="text-center mt-4 text-primary-color font-weight-bold"
                  >
                    {{ $t("userLogin") }}
                    {{ returnColor() ? "-UAT" : "" }}
                  </h2>
                  <loginTypeVue @selectedLogin="selectedLogin" />
                  <br />
                  <v-alert
                    v-if="alertMsg.status"
                    :timeout="alertMsg.timeout"
                    :color="alertMsg.color"
                    :type="alertMsg.type"
                    dismissible
                  >
                    {{ alertMsg.text }}
                  </v-alert>

                  <form class="mb-4">
                    <label class="text-midgray-color"
                      >{{
                        login_type === "mobile"
                          ? $t("loginUsingMobilePassword")
                          : $t("loginUsingEmailPassword")
                      }}
                    </label>

                    <v-select
                      v-model="user_details.user_type"
                      :items="user_types"
                      :label="$t('user_type')"
                      :placeholder="$t('user_type_placeholder')"
                      item-text="txt"
                      item-value="val"
                      :rules="user_type_rules"
                    >
                      <template v-slot:prepend-inner class="pt-0">
                        <v-icon class="me-4 pb-2 font-size">{{
                          user_details.user_type === "IND"
                            ? "mdi-account-tie"
                            : "mdi-briefcase-account"
                        }}</v-icon>
                      </template></v-select
                    >
                    <v-text-field
                      v-if="login_type === 'mobile'"
                      type="number"
                      hide-spin-buttons
                      id="sendAmount"
                      v-model="user_details.mobile_number"
                      :hint="'+61 xx xxxx xxxx'"
                      color="primary"
                      :placeholder="$t('mobile_number_placeholder')"
                      required
                      :rules="mobileRules"
                      prefix="+61"
                      class="custome-text-field"
                      :label="$t('mobile_number')"
                      @keydown="enterKeyPressed"
                    >
                      <template v-slot:prepend-inner class="pt-0">
                        <country-flag country="AU" class="mb-0 me-2" />
                      </template>
                    </v-text-field>

                    <v-text-field
                      v-else
                      v-model="user_details.email"
                      :label="$t('emailID')"
                      :placeholder="$t('email_placeholder')"
                      :rules="emailValidations"
                      clearable
                      append-icon="mdi-account-outline"
                    ></v-text-field>
                    <v-text-field
                      v-model="user_details.password"
                      :placeholder="$t('password_placholder')"
                      :append-icon="
                        isVisible ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
                      "
                      @click:append="() => (isVisible = !isVisible)"
                      :type="isVisible ? 'text' : 'password'"
                      :label="$t('password')"
                      @keydown.space.prevent
                      :rules="passwordRules"
                      clearable
                      @keydown="enterKeyPressed"
                    ></v-text-field>
                    <v-btn
                      block
                      rounded
                      color="primary"
                      @click="onClickLogin"
                      class="btn-primary mt-3 text-capitalize"
                      >{{ $t("signin") }}</v-btn
                    >
                  </form>

                  <div
                    class="d-flex justify-content-between border-bottom mb-2 pb-1"
                  >
                    <v-btn
                      class="text-capitalize p-0"
                      color="grey darken-2"
                      plain
                      @click="$router.push('/forgotpassword')"
                    >
                      {{ $t("forgotPassword") }}?
                    </v-btn>

                    <v-btn
                      class="text-capitalize text-primary-color p-0"
                      plain
                      @click="$router.push('/signup')"
                    >
                      {{ $t("createNewAccount") }}
                    </v-btn>
                  </div>

                  <v-btn-toggle
                    v-model="languageSelected"
                    tile
                    color="primary accent-3"
                    group
                    class="d-flex justify-content-around mb-2"
                    @change="languageChanged"
                  >
                    <v-btn
                      v-for="(lang, idx) in language_options"
                      :key="idx"
                      :value="lang.value"
                      class="text-capitalize px-5 toggle-btn"
                    >
                      {{ lang.text }}
                    </v-btn>
                  </v-btn-toggle>
                </div>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <loading
        :active="loader"
        :is-full-page="fullPage"
        :opacity="0.9"
        color="#ff6b00"
        :height="height"
        :width="width"
      />
    </section>
    <v-dialog v-model="contact_us" width="350">
      <v-card rounded="xl" class="mx-auto">
        <v-card-text class="pa-4 d-flex flex-column align-items-center">
          <div class="mt-4">
            <v-icon
              color="primary"
              large
              class="account-icons m-auto text-center"
              style="border: 2px solid #ff6b00"
            >
              mdi-account-tie
            </v-icon>
          </div>
          <p class="display-1 primary--text m-auto text-center">
            {{ $t("contact_us") }}
          </p>
          <p class="text-center text-subtitle-2">
            {{ $t("customer_support_text") }}
          </p>
          <div>
            <p>
              <v-icon>mdi-mail</v-icon> {{ $t("support_email") }}:
              <b class="primary--text">support@qqpay.io</b>
            </p>
            <p>
              <v-icon>mdi-phone</v-icon> {{ $t("support_phone") }}::
              <b class="primary--text">010-2573947</b>
            </p>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import Loading from "vue-loading-overlay";
import { webbreakdown } from "../../utils/breakpoints";
import { mapMutations } from "vuex";
import { getCookieValue } from "../../utils/getCookies";
import { returnuserTypes } from "../../utils/constDropdowns/userType";
import userservice from "@/services/UserService/userservice";
import alerts from "@/mixins/alerts";
import loginTypeVue from "./loginType.vue";
import { i18n as $ } from "../../locale/index";
export default {
  name: "Login",
  mixins: [alerts],
  mounted() {
    sessionStorage.clear();
    console.log("this.$route.query", this.$route.query.user_type);
    const user_type = this.$route.query.user_type;
    if (user_type === "B") {
      this.user_details.user_type = "BUS";
    }
  },
  components: {
    loginTypeVue,
    Loading,
  },
  data() {
    return {
      webbreakdown,
      getCookieValue,
      login_type: "email",
      isVisible: false,
      valid: true,
      user_details: {
        mobile_number: this.$route.params.mobile_number || "",
        email: "",
        password: "",
        user_type: "BUS",
      },
      user_types: returnuserTypes(),
      loader: false,
      contact_us: false,
      languageSelected: "en-us",
      dismissCountDown: 20,
      showDismissibleAlert: false,
      language_options: [
        {
          text: "English",
          value: "en-us",
        },
        {
          text: "Chinese",
          value: "zh-cn",
        },
      ],
      fullPage: true,
      height: 100,
      width: 100,
      alertMsg: {
        status: false,
        text: "",
        timeout: 2000,
        color: "blue-grey",
        type: "info",
      },
      emailValidations: [
        (v) => !!v || $.t("emailMsg1"),
        (v) =>
          /^[\w+.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/.test(v) ||
          $.t("emailMsg2"),
      ],
      mobileRules: [
        (v) => !!v || $.t("mobile_required"),
        (v) => /^(([0-9]*){20})$/.test(v) || $.t("mobile_required"),

        (v) => (v && v.length >= 9) || $.t("min_mobile_number"),
        (v) => (v && v.length <= 12) || $.t("max_mobile_number"),
      ],
      passwordRules: [(v) => !!v || $.t("password_required")],
      user_type_rules: [(v) => !!v || $.t("usertype_req")],
    };
  },
  methods: {
    ...mapMutations(["setUserDetails"]),
    selectedLogin(data) {
      this.login_type = data;
    },
    returnColor() {
      if (process.env.VUE_APP_DARK === "true") {
        return true;
      } else {
        return false;
      }
    },
    enterKeyPressed(event) {
      if (event.keyCode == 13) this.onClickLogin();
    },
    checkNumber(val) {
      if (val.substring(0, 2) === "61") {
        return val;
      } else {
        return "61" + val;
      }
    },
    async CheckTFAstatus(userDetails) {
      try {
        this.loader = true;
        const responseData = await userservice.CheckTFAstatus(userDetails);
        if (responseData && responseData.data.success) {
          const Format_num = this.checkNumber(this.user_details.mobile_number);
          const factor_check = responseData.data.data[0];
          if (factor_check.is_two_factor_check) {
            this.submitData(Format_num);
          } else {
            const has_cookie = this.getCookieValue("2FA_status");
            const has_cookie_mobilenumber =
              this.getCookieValue("mobile_number");
            // to enble and disable the 2FA status
            if (has_cookie && has_cookie_mobilenumber === Format_num) {
              this.UpadteTFAstatus({
                mobile_number: Format_num,
                status: false,
                user_type: this.user_details.user_type,
              });
            } else {
              this.UpadteTFAstatus({
                mobile_number: Format_num,
                status: true,
                user_type: this.user_details.user_type,
              });
            }
          }
        } else {
          this.showAlertError(responseData.data.message);
          this.loader = false;
        }
      } catch (error) {
        this.showAlertError("something went wrong please try again sometime!");
        console.log(error);
        this.loader = false;
      }
    },
    async UpadteTFAstatus(userDetails) {
      console.log("datauser", userDetails);
      try {
        this.loader = true;
        const responseData = await userservice.UpadteTFAstatus(userDetails);
        console.log("Authentication", responseData);

        if (responseData && responseData.data.success) {
          this.submitData(userDetails.mobile_number);
          this.loader = false;
          console.log(responseData.data.data[0]);
        } else {
          this.showAlertError(responseData.data.message);
          this.loader = false;
        }
      } catch (error) {
        this.showAlertError("something went wrong please try again sometime!");
        console.log(error);
        this.loader = false;
      }
    },
    async onClickLogin() {
      if (this.$refs.form.validate()) {
        if (this.login_type == "mobile") {
          const Format_num = this.checkNumber(this.user_details.mobile_number);
          this.CheckTFAstatus({
            mobile_number: Format_num,
            user_type: this.user_details.user_type,
          });
        } else {
          this.submitData(this.user_details.email);
        }
      }
    },
    async submitData(user_name) {
      this.alertMsg.status = false;
      const userDetails = {
        user_name: user_name,
        password: this.user_details.password,
        user_type: this.user_details.user_type,
      };
      this.$store.commit("username", user_name);
      sessionStorage.setItem("username", user_name);
      this.$store.dispatch("Authentication/saveUserEmail", user_name);
      try {
        this.loader = true;
        const responseData = await userservice.login(userDetails, {
          login_type: this.login_type === "mobile" ? "Mobile" : "Email",
        });
        if (responseData && responseData.data.success) {
          this.loader = false;
          this.showAlertSuccess(responseData.data.message);
          console.log(responseData.data.data[0]);
          if (responseData.data.data[0].is_two_factor_auth) {
            sessionStorage.setItem(
              "selected_user_type",
              this.user_details.user_type
            );
            setTimeout(
              () =>
                this.$router.push({
                  name: "Two Factor Auth",
                  query: {
                    login_type: this.login_type,
                  },
                }),
              1000
            );
          } else {
            this.setUserDetails(responseData.data.data[0]);
            if (responseData.data.data[0].user_type) {
              sessionStorage.setItem(
                "user_type",
                responseData.data.data[0].user_type
              );
            }
            sessionStorage.setItem("selected_login_type", this.login_type);
            sessionStorage.setItem("selected_user_name", user_name);
            this.$setLocalStorage(
              "is_payment_gateway",
              responseData.data.data[0].is_payment_gateway
            );
            this.$setLocalStorage(
              "supported_payment_modes",
              responseData.data.data[0].supported_payment_modes
            );
            this.$setLocalStorage(
              "is_check_pin",
              responseData.data.data[0].is_check_pin
            );
            sessionStorage.setItem(
              "is_self_beneficiary",
              responseData.data.data[0].is_self_beneficiary ? true : false
            );
            this.$setLocalStorage(
              "is_partner",
              responseData.data.data[0].is_partner
            );
            this.$setLocalStorage("logo", responseData.data.data[0].logo);
            this.$setLocalStorage("is_logo", responseData.data.data[0].is_logo);
            this.$setLocalStorage(
              "partner_type",
              responseData.data.data[0].partner_type
            );
            sessionStorage.setItem(
              "refresh_token",
              responseData.data.data[0].refresh_token
            );
            sessionStorage.setItem(
              "access_token",
              responseData.data.data[0].access_token
            );
            sessionStorage.setItem(
              "user_id",
              responseData.data.data[0].user_id
            );
            sessionStorage.setItem(
              "complete_registration",
              responseData.data.data[0].complete_registration
            );
            sessionStorage.setItem(
              "is_kyc",
              responseData.data.data[0].kyc_status
            );

            this.$store.commit(
              "access_token",
              responseData.data.data[0].access_token
            );
            this.$store.commit(
              "complete_registration",
              responseData.data.data[0].complete_registration
            );
            setTimeout(() => this.$router.push({ name: "HomeView" }), 1000);
          }
        } else {
          this.showAlertError(responseData.data.message);
          this.loader = false;
        }
      } catch (error) {
        console.log(error);
        this.showAlertError("something went wrong please try again sometime!");
        this.loader = false;
      }
    },
    languageChanged() {
      console.log("languageChanged: ", this.languageSelected);
      this.$i18n.locale = this.languageSelected;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/global.scss";
.lead {
  font-weight: 400;
  color: $txt;
}
.toggle-btn {
  min-height: 67px !important;
  width: 150px !important;
}

.logo_img {
  max-height: 50px;
}
.logo_img_lg {
  max-height: 100px;
}
.div-signup {
  color: $txt;
  cursor: pointer;
}
.login {
  height: 100%;
}
.invalid-feedback-email {
  display: flex;
  flex-direction: column;
  text-align: initial;
  font-size: 12px;
  background-color: #f5f5f5;
  color: #dc3545;
}
.invalid-feedback-password {
  display: flex;
  flex-direction: column;
  text-align: initial;
  font-size: 12px;
  background-color: #f5f5f5;
  color: #dc3545;
}
.language-selection {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.button#input-type__BV_toggle_ {
  font-size: 10px;
}
.forgot-link {
  margin-top: 26px;
  line-height: 3rem;
}

.shadow_class {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  // width: %;
  background: #f8f9fa;
  border-radius: 7px;
}
.font-size {
  font-size: 28px;
}

/* For Mobile Phones Portrait or Landscape View */
@media screen and (max-device-width: 640px) {
  .login-main-div {
    margin-top: 35% !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
</style>
